<template>
  <div class="wrapper">
  </div>
</template>

<script>
import { wxlogin } from '../../api'
export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 获取连接参数正则
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 获取当前网页的协议+域名
    getPageBaseUrl () {
      let baseURL = ''
      if (!window.location.origin) { // 兼容IE，IE11版本下location.origin为undefined
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      } else {
        baseURL = window.location.origin
      }
      return baseURL
    },
    
  },
  created() {
    var state = ''
    if (this.$route.query.id != null) {
      localStorage.setItem('preview',JSON.stringify(this.$route.query))
    }
    
    if (this.getQueryString("code") == null) {
      var Url = "https://open.weixin.qq.com/connect/oauth2/authorize?";
      var appid = "wx806dd060ba9501c8";
      var redirect_uri = encodeURIComponent(this.getPageBaseUrl()+'/test'+'/#/previewLogin');
      console.log(redirect_uri);
    //   window.location.replace(
    //     Url +
    //       "appid=" +
    //       appid +
    //       "&redirect_uri=" +
    //       redirect_uri +
    //       "&response_type=code&scope=snsapi_userinfo&state="+state+"#wechat_redirect"
    //   );
    } else {
      wxlogin({ code: this.getQueryString("code") }).then((res) => {
        var data = res.data.data;
        
        console.log(data);
      });
    }
  },
  mounted() {
    
  },
};
</script>
<style scoped>
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
}
</style>